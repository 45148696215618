// material-ui
import { Stack, Chip, Typography } from '@mui/material';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = () => {
    return (
        <Stack direction="row" spacing={1} p={3} pb={2} alignItems="center">
            <Typography variant="h5">원고판다</Typography>
            <Chip label="1.0.4" size="small" />
        </Stack>
    );
};

export default DrawerHeader;
