import { useNavigate } from 'react-router-dom';

// material-ui
import { Typography, Grid, Stack } from '@mui/material';
import MyStack from 'components/MyStack';

// assets
import { SwapRightOutlined } from '@ant-design/icons';

const UserEntry = () => {
    const navigate = useNavigate();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            sx={{
                minHeight: '100vh',
                backgroundImage: 'url(/images/entry_bg.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <MyStack justifyContent="center" spacing={{ xs: 4, md: 10 }}>
                <Stack
                    p={3}
                    sx={{
                        height: '180px',
                        bgcolor: '#ff4d4f',
                        width: '480px',
                        maxWidth: '90vw',
                        // borderTopRightRadius: '30px',
                        cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/writer/home`)}
                >
                    <Typography variant="h6" fontSize={{ md: '16px', xs: '14px' }} color="#cccccc">
                        하고자 한다는 마음만 있다면 누구나!
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography color="#fff" sx={{ fontSize: { md: '32px', xs: '26px' } }}>
                            작가
                        </Typography>
                        <SwapRightOutlined style={{ color: '#fff', fontSize: '2rem' }} />
                    </Stack>
                </Stack>
                <Stack
                    p={3}
                    sx={{
                        height: '180px',
                        bgcolor: '#2d5a92',
                        width: '480px',
                        maxWidth: '90vw',
                        // borderTopRightRadius: '30px',
                        cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/client/home`)}
                >
                    <Typography variant="h6" fontSize={{ md: '16px', xs: '14px' }} color="#cccccc">
                        주문부터 완료까지 단! 3일
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography color="#fff" sx={{ fontSize: { md: '32px', xs: '26px' } }}>
                            클라이언트
                        </Typography>
                        <SwapRightOutlined style={{ color: '#fff', fontSize: '2rem' }} />
                    </Stack>
                    <Typography variant="h6" fontSize={{ md: '16px', xs: '14px' }} color="#e3ac49" mt={4}>
                        최대 10% 페이백
                    </Typography>
                </Stack>
            </MyStack>
        </Grid>
    );
};

export default UserEntry;
