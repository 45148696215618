import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import Header from './header/HeaderWriter';
import LoginDialog from 'pages/writer/LoginDialog';
import RegisterDialog from 'pages/writer/RegisterDialog';
import AlertResult from 'components/AlertResult';

import { logout } from 'store/reducers/auth';
import { activeLogin, activeRegister } from 'store/reducers/auth';

import '@toast-ui/editor/dist/toastui-editor.css';

const WriterLayout = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.writer.token);
    const openLoginDlg = useSelector((state) => state.auth.openLogin);
    const openRegisterDlg = useSelector((state) => state.auth.openRegister);

    const [openSnack, setOpenSnack] = useState(false);
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState('success');

    useEffect(() => {
        onCloseLoginDlg();
        onCloseRegisterDlg();
        if (token) {
            const decodeJwt = jwt_decode(token);
            if (decodeJwt.exp * 1000 < Date.now()) {
                dispatch(logout({ type: 'writer' }));
                console.log('writerlayout: token expired');
            }
        }
    }, []);

    const onShowAlert = (msg, serverity) => {
        setMsg(msg);
        setSeverity(serverity);
        setOpenSnack(true);
    };

    const closeSnack = () => {
        setOpenSnack(false);
    };

    const onCloseLoginDlg = () => {
        dispatch(activeLogin({ open: false }));
    };

    const onCloseRegisterDlg = () => {
        dispatch(activeRegister({ open: false }));
    };

    return (
        <main className="main-wrapper">
            <Header />
            <Outlet />
            {openLoginDlg && <LoginDialog openDlg={openLoginDlg} onCloseDlg={onCloseLoginDlg} onShowAlert={onShowAlert} />}
            {openRegisterDlg && <RegisterDialog openDlg={openRegisterDlg} onCloseDlg={onCloseRegisterDlg} onShowAlert={onShowAlert} />}
            <AlertResult openSnack={openSnack} closeSnack={closeSnack} msg={msg} severity={severity} ms={2000} />
        </main>
    );
};

export default WriterLayout;
