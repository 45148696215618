import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

import ClientLayout from 'layout/user/ClientLayout';

// const ClientAuthLogin = Loadable(lazy(() => import('pages/client/AuthLogin')));
// const ClientAuthRegister = Loadable(lazy(() => import('pages/client/AuthRegister')));
const ClientHome = Loadable(lazy(() => import('pages/client/Home')));
const WongoSample = Loadable(lazy(() => import('pages/client/WongoSample')));
const ClientNotice = Loadable(lazy(() => import('pages/client/Notice')));
const ClientFAQ = Loadable(lazy(() => import('pages/client/FAQ')));
const ClientQuestion = Loadable(lazy(() => import('pages/client/Question')));
const Point = Loadable(lazy(() => import('pages/client/Point')));
const Profile = Loadable(lazy(() => import('pages/client/Profile')));
const Order = Loadable(lazy(() => import('pages/client/Order')));
const Algorithm = Loadable(lazy(() => import('pages/client/Algorithm')));

const ClientRoutes = {
    path: 'client',
    element: <ClientLayout />,
    children: [
        {
            index: true,
            element: <ClientHome />
        },
        {
            path: 'home',
            element: <ClientHome />
        },
        {
            path: 'sample',
            element: <WongoSample />
        },
        {
            path: 'service-notice',
            element: <ClientNotice />
        },
        {
            path: 'service-faq',
            element: <ClientFAQ />
        },
        {
            path: 'service-question',
            element: <ClientQuestion />
        },
        {
            path: 'point',
            element: <Point />
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'order',
            element: <Order />
        },
        {
            path: 'algorithm',
            element: <Algorithm />
        }
    ]
};
export default ClientRoutes;
