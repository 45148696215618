import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/admin/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/admin/Dashboard')));

// render - admin panel
const ClientManage = Loadable(lazy(() => import('pages/admin/ClientManage')));
const WriterManage = Loadable(lazy(() => import('pages/admin/WriterManage')));
const PointManage = Loadable(lazy(() => import('pages/admin/PointManage')));

//render - order panel
const OrderManage = Loadable(lazy(() => import('pages/admin/OrderManage')));
const TaxManage = Loadable(lazy(() => import('pages/admin/TaxManage')));
const RatingManage = Loadable(lazy(() => import('pages/admin/RatingManage')));
const MsgManage = Loadable(lazy(() => import('pages/admin/MsgManage')));
const CalcManage = Loadable(lazy(() => import('pages/admin/CalcManage')));
const GradePriceManage = Loadable(lazy(() => import('pages/admin/GradePriceManage')));

//render - client panel
const WongoSample = Loadable(lazy(() => import('pages/admin/WongoSample')));
const ClientNotice = Loadable(lazy(() => import('pages/admin/ClientNotice')));
const ClientFAQ = Loadable(lazy(() => import('pages/admin/ClientFAQ')));
const ClientQuestion = Loadable(lazy(() => import('pages/admin/ClientQuestion')));

//render - writer panel
const WriterCategory = Loadable(lazy(() => import('pages/admin/WriterCategory')));
const WriterGuide = Loadable(lazy(() => import('pages/admin/WriterGuide')));
const WriterNotice = Loadable(lazy(() => import('pages/admin/WriterNotice')));
const WriterFAQ = Loadable(lazy(() => import('pages/admin/WriterFAQ')));
const WriterQuestion = Loadable(lazy(() => import('pages/admin/WriterQuestion')));
const TestSchedule = Loadable(lazy(() => import('pages/admin/TestSchedule')));
const TestGuide = Loadable(lazy(() => import('pages/admin/TestGuide')));

// render - authentification
const AuthLogin = Loadable(lazy(() => import('pages/admin/AuthLogin')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: 'admin',
    element: <MinimalLayout />,
    children: [
        {
            index: true,
            element: <AuthLogin />
        },
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: '',
            element: <MainLayout />,
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardDefault />
                },
                {
                    path: 'client-manage',
                    element: <ClientManage />
                },
                {
                    path: 'writer-manage',
                    element: <WriterManage />
                },
                {
                    path: 'point-manage',
                    element: <PointManage />
                },
                {
                    path: 'order-manage',
                    element: <OrderManage />
                },
                {
                    path: 'tax-manage',
                    element: <TaxManage />
                },
                {
                    path: 'rating-manage',
                    element: <RatingManage />
                },
                {
                    path: 'msg-manage',
                    element: <MsgManage />
                },
                {
                    path: 'clac-manage',
                    element: <CalcManage />
                },
                {
                    path: 'grade-price',
                    element: <GradePriceManage />
                },
                {
                    path: 'wongo-sample',
                    element: <WongoSample />
                },
                {
                    path: 'client-notice',
                    element: <ClientNotice />
                },
                {
                    path: 'client-faq',
                    element: <ClientFAQ />
                },
                {
                    path: 'client-question',
                    element: <ClientQuestion />
                },
                {
                    path: 'writer-category',
                    element: <WriterCategory />
                },
                {
                    path: 'writer-guide',
                    element: <WriterGuide />
                },
                {
                    path: 'writer-notice',
                    element: <WriterNotice />
                },
                {
                    path: 'writer-faq',
                    element: <WriterFAQ />
                },
                {
                    path: 'writer-question',
                    element: <WriterQuestion />
                },
                {
                    path: 'test-schedule',
                    element: <TestSchedule />
                },
                {
                    path: 'test-guide',
                    element: <TestGuide />
                }
            ]
        }
    ]
};

export default AdminRoutes;
