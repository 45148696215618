import PropTypes from 'prop-types';

import { Alert, Snackbar } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';

const AlertResult = ({ openSnack, closeSnack, msg, severity, ms }) => {
    // const { enqueueSnackbar } = useSnackbar();
    return (
        <Snackbar open={openSnack} autoHideDuration={ms} onClose={closeSnack} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert elevation={6} variant="filled" onClose={closeSnack} severity={severity} sx={{ width: '100%', color: '#ffffff' }}>
                {msg}
            </Alert>
        </Snackbar>
        // <SnackbarProvider maxSnack={3} autoHideDuration={ms}>
        //     <Alert elevation={6} variant="filled" onClose={closeSnack} severity={severity} sx={{ width: '100%', color: '#ffffff' }}>
        //         {msg}
        //     </Alert>
        // </SnackbarProvider>
    );
};

AlertResult.propTypes = {
    openSnack: PropTypes.bool,
    closeSnack: PropTypes.func,
    msg: PropTypes.string,
    severity: PropTypes.string,
    ms: PropTypes.number
};

export default AlertResult;
