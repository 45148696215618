import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

//material-ui
import {
    Button,
    IconButton,
    OutlinedInput,
    Stack,
    Typography,
    Link,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Dialog
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

//assets
import { CloseOutlined } from '@ant-design/icons';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';

// project import
import useAxios from 'utils/useAxios';
import { login as signin, activeRegister } from 'store/reducers/auth';

const LoginDialog = ({ openDlg, onCloseDlg, onShowAlert }) => {
    const dispatch = useDispatch();
    const api = useAxios('writer');

    const [keepLogin, setKeepLogin] = useState(false);

    const login = async (uid, pwd) => {
        try {
            const response = await api.post('/login', {
                uid: uid.trim(),
                pwd: pwd,
                type: 2,
                keep: keepLogin ? 1 : 0
            });

            onShowAlert('로그인되었습니다', 'success');
            const info = { type: 'writer', ...response.data };
            dispatch(signin(info));
            onCloseDlg();
        } catch (error) {
            onShowAlert('입력하신 정보가 정확치 않습니다.', 'error');
        }
    };

    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return;
        onCloseDlg();
    };

    const openRegisterDlg = () => {
        onCloseDlg();
        dispatch(activeRegister({ open: true }));
    };

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={openDlg}
            onClose={handleClose}
            disableEscapeKeyDown
            sx={{ '& .MuiPaper-root': { borderRadius: '20px' } }}
        >
            <DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={onCloseDlg}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined />
                </IconButton>
                <Stack px={{ sm: 4, xs: 0 }} py={6}>
                    <Typography variant="h6" textAlign="center">
                        본 서비스를 이용하기 위해 <br /> 로그인을 하셔야 합니다.
                    </Typography>
                    <Formik
                        initialValues={{
                            uid: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            uid: Yup.string().max(30).required('아이디를 입력해주세요'),
                            password: Yup.string().max(50).required('비밀번호를 입력해주세요')
                        })}
                        onSubmit={async (values, { setErrors, setSubmitting }) => {
                            try {
                                setSubmitting(false);

                                await login(values.uid, values.password);
                            } catch (err) {
                                setErrors({ submit: err.message });
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Stack mt={5}>
                                    <OutlinedInput
                                        name="uid"
                                        value={values.uid}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="아이디를 입력해주세요"
                                        fullWidth
                                        error={Boolean(touched.uid && errors.uid)}
                                        sx={{ borderRadius: '8px' }}
                                    />

                                    <OutlinedInput
                                        fullWidth
                                        id="password"
                                        type="password"
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.password && errors.password)}
                                        placeholder="비밀번호를 입력해주세요"
                                        sx={{ mt: 1.5, borderRadius: '8px' }}
                                    />

                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="error"
                                        size="large"
                                        fullWidth
                                        disableElevation
                                        loading={isSubmitting}
                                        loadingPosition="end"
                                        sx={{ mt: 4, py: '12px', borderRadius: '30px' }}
                                    >
                                        <Typography variant="body1">들어가기</Typography>
                                    </LoadingButton>

                                    <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<CircleUnchecked />}
                                                    checkedIcon={<CircleCheckedFilled />}
                                                    name="checkKeep"
                                                    color="error"
                                                    size="small"
                                                    checked={keepLogin}
                                                    onChange={(event) => setKeepLogin(event.target.checked)}
                                                />
                                            }
                                            label={<Typography variant="caption">로그인 유지</Typography>}
                                        />
                                        <Stack direction="row" spacing={0.5}>
                                            <Link variant="caption" color="primary" href="/find-id" target="_blank" underline="always">
                                                아이디
                                            </Link>
                                            <Link variant="caption" color="primary" href="/reset-pwd" target="_blank" underline="always">
                                                비밀번호
                                            </Link>
                                            <Typography variant="caption" color="text.primary">
                                                찾기
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                        sx={{
                                            mt: 4,
                                            bgcolor: '#000',
                                            '&:hover': {
                                                backgroundColor: '#404040'
                                            },
                                            py: '12px',
                                            borderRadius: '30px'
                                        }}
                                        onClick={openRegisterDlg}
                                    >
                                        <Typography variant="body1">가입하기</Typography>
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default LoginDialog;
