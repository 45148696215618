// assets
import { FileWordOutlined, ContainerOutlined, StarOutlined, CalculatorOutlined, DollarOutlined, MailOutlined } from '@ant-design/icons';

// icons
const icons = {
    FileWordOutlined,
    ContainerOutlined,
    StarOutlined,
    CalculatorOutlined,
    DollarOutlined,
    MailOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const order = {
    id: 'order_panel',
    title: 'ORDER MANAGER',
    type: 'group',
    children: [
        {
            id: 'order_manage',
            title: '원고의뢰 관리',
            type: 'item',
            url: '/admin/order-manage',
            icon: icons.FileWordOutlined
        },
        {
            id: 'tax_manage',
            title: '세금계산서 관리',
            type: 'item',
            url: '/admin/tax-manage',
            icon: icons.ContainerOutlined
        },
        {
            id: 'rating_manage',
            title: '별점 관리',
            type: 'item',
            url: '/admin/rating-manage',
            icon: icons.StarOutlined
        },
        {
            id: 'msg_manage',
            title: '의견함',
            type: 'item',
            url: '/admin/msg-manage',
            icon: icons.MailOutlined
        },
        {
            id: 'calc_manage',
            title: '정산 관리',
            type: 'item',
            url: '/admin/clac-manage',
            icon: icons.CalculatorOutlined
        },
        {
            id: 'grade_price',
            title: '급수별 정산금액',
            type: 'item',
            url: '/admin/grade-price',
            icon: icons.DollarOutlined
        }
    ]
};

export default order;
