import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    admin: {
        isLoggedIn: false,
        gm_no: 0,
        gm_id: '',
        gm_name: '',
        token: ''
    },
    client: {
        isLoggedIn: false,
        gm_no: 0,
        gm_id: '',
        gm_name: '',
        token: ''
    },
    writer: {
        isLoggedIn: false,
        gm_no: 0,
        gm_id: '',
        gm_name: '',
        token: ''
    },
    manager: {
        isLoggedIn: false,
        gm_no: 0,
        gm_id: '',
        gm_name: '',
        token: ''
    },
    openLogin: false,
    openRegister: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state[action.payload.type].isLoggedIn = true;
            state[action.payload.type].gm_no = action.payload.gm_no;
            state[action.payload.type].gm_id = action.payload.gm_id;
            state[action.payload.type].gm_name = action.payload.gm_name;
            state[action.payload.type].token = action.payload.token;
        },
        logout: (state, action) => {
            state[action.payload.type].isLoggedIn = false;
            state[action.payload.type].gm_no = 0;
            state[action.payload.type].gm_id = '';
            state[action.payload.type].gm_name = '';
            state[action.payload.type].token = '';
        },
        resetToken: (state, action) => {
            state[action.payload.type].token = action.payload.token;
        },
        setName: (state, action) => {
            state[action.payload.type].gm_name = action.payload.gm_name;
        },
        activeLogin: (state, action) => {
            state.openLogin = action.payload.open;
        },
        activeRegister: (state, action) => {
            state.openRegister = action.payload.open;
        }
    }
});

export const { login, logout, resetToken, setName, activeLogin, activeRegister } = authSlice.actions;

export default authSlice.reducer;
