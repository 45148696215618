import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

//material-ui
import {
    Box,
    Button,
    Grid,
    Stack,
    Typography,
    Link,
    TextField,
    FormControlLabel,
    Checkbox,
    IconButton,
    Dialog,
    DialogContent
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

//assets
import { CloseOutlined } from '@ant-design/icons';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';

// project import
import useAxios from 'utils/useAxios';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import { login as signin } from 'store/reducers/auth';

const AuthRegister = ({ openDlg, onCloseDlg, onShowAlert }) => {
    const api = useAxios('writer');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [level, setLevel] = useState();
    const [checkTerms, setCheckTerms] = useState(false);
    const [checkAgree, setCheckAgree] = useState(false);

    useEffect(() => {
        changePassword('');

        const { IMP } = window;
        IMP.init(process.env.REACT_APP_IMP_CODE);
    }, []);

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setLevel(strengthColor(temp));
    };

    const certPhone = async (setFieldValue) => {
        IMP.certification({}, async (response) => {
            const { success, error_msg, imp_uid } = response;
            if (success) {
                const response = await api.post('/signup/getPhoneNumber', { imp_no: imp_uid });
                const phone = response.data.phone;
                if (phone) {
                    setFieldValue('phone', `${phone.substring(0, 3)}-${phone.substring(3, 7)}-${phone.substring(7)}`, true);
                    return;
                }
            }
            alert(`인증 실패 : ${error_msg}`);
        });
    };

    const checkValidInfo = async (uid, gm_phone) => {
        const reponse = await api.post('/signup/checkValidInfo', {
            uid,
            gm_phone
        });
        return reponse.data;
    };

    const signup = async (uid, name, email, gm_phone, pwd) => {
        const response = await api.post('/signup', {
            uid,
            name,
            email,
            gm_phone,
            pwd,
            type: 2 //writer
        });

        if (response.data.status === 'success') {
            const info = { type: 'writer', ...response.data };
            dispatch(signin(info));

            onShowAlert('회원가입을 축하드립니다. 마이페이지에서 작가관련정보를 완성해 주세요.', 'success');
            onCloseDlg();

            navigate('/writer/profile');
        }
    };

    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return;
        onCloseDlg();
    };

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={openDlg}
            onClose={handleClose}
            disableEscapeKeyDown
            sx={{ '& .MuiPaper-root': { borderRadius: '20px' } }}
        >
            <DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={onCloseDlg}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined />
                </IconButton>
                <Stack px={{ sm: 4, xs: 0 }} py={6}>
                    {/*<Typography variant="h6" textAlign="center">*/}
                    {/*    신입 지원을 원하시는 분은*/}
                    {/*    <br /> 가입 후 마이페이지를 작성 완료해 주세요.*/}
                    {/*</Typography>*/}
                    <Formik
                        initialValues={{
                            uid: '',
                            name: '',
                            email: '',
                            password: '',
                            phone: ''
                        }}
                        validationSchema={Yup.object().shape({
                            uid: Yup.string().max(30).required('아이디를 입력해주세요'),
                            name: Yup.string().max(20).required('이름을 입력해주세요'),
                            email: Yup.string().max(100).required('이메일을 입력해주세요').email('이메일형식이 틀립니다'),
                            phone: Yup.string().max(20).required('전화번호를 입력해주세요'), // .matches(phoneRegExp, '전화번호형식이 틀립니다'),
                            password: Yup.string().max(50).required('비밀번호를 입력해주세요')
                        })}
                        onSubmit={async (values, { setErrors, setSubmitting }) => {
                            try {
                                if (!checkTerms) {
                                    onShowAlert('이용약관에 동의하셔야 합니다.', 'error');
                                    return;
                                }
                                if (!checkAgree) {
                                    onShowAlert('개인정보 수집 및 이용에 동의하셔야 합니다.', 'error');
                                    return;
                                }
                                setSubmitting(true);
                                const validInfo = await checkValidInfo(values.uid, values.phone);
                                if (!validInfo.uid) {
                                    onShowAlert('그 아이디는 이미 사용중입니다.', 'error');
                                    setSubmitting(false);
                                    return;
                                }
                                if (!validInfo.phone) {
                                    onShowAlert('그 전화번호는 이미 사용중입니다.', 'error');
                                    setSubmitting(false);
                                    return;
                                }
                                await signup(values.uid, values.name, values.email, values.phone, values.password);
                            } catch (err) {
                                setErrors({ submit: err.message });
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={1.5} mt={3}>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            id="uid"
                                            name="uid"
                                            value={values.uid}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="아이디"
                                            fullWidth
                                            error={Boolean(touched.uid && errors.uid)}
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '8px' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            id="name"
                                            name="name"
                                            value={values.name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="이름"
                                            fullWidth
                                            error={Boolean(touched.name && errors.name)}
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '8px' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={7} sm={8}>
                                        <TextField
                                            id="phone"
                                            name="phone"
                                            value={values.phone}
                                            // onBlur={handleBlur}
                                            // onChange={handleChange}
                                            // inputProps={{ maxLength: 13 }}
                                            placeholder="휴대폰번호"
                                            // helperText="010-1234-5678 형식으로 입력해주세요"
                                            fullWidth
                                            error={Boolean(errors.phone)}
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '8px' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={4}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="large"
                                            fullWidth
                                            disableElevation
                                            onClick={() => certPhone(setFieldValue)}
                                            sx={{ py: '10px', borderRadius: '30px' }}
                                        >
                                            <Typography variant="body1">본인인증</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={values.email}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="이메일주소"
                                            helperText="추후 비밀번호 분실 시 이메일이 이용됩니다"
                                            fullWidth
                                            error={Boolean(touched.email && errors.email)}
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '8px' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="password"
                                            name="password"
                                            fullWidth
                                            placeholder="비밀번호"
                                            type="password"
                                            value={values.password}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e);
                                                changePassword(e.target.value);
                                            }}
                                            error={Boolean(touched.password && errors.password)}
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '8px' } }}
                                        />
                                        <Stack direction="row" spacing={2} alignItems="center" mt={1}>
                                            <Box sx={{ bgcolor: level?.color, width: 85, height: 8, borderRadius: '7px' }} />
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<CircleUnchecked />}
                                                    checkedIcon={<CircleCheckedFilled />}
                                                    name="checkTerms"
                                                    color="error"
                                                    size="small"
                                                    checked={checkTerms}
                                                    onChange={(event) => setCheckTerms(event.target.checked)}
                                                />
                                            }
                                            label={<Typography variant="body2">이용약관(필수)</Typography>}
                                        />
                                        <Link variant="caption" color="text.primary" href="/terms1.html" target="_blank" underline="always">
                                            보기
                                        </Link>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -2 }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<CircleUnchecked />}
                                                    checkedIcon={<CircleCheckedFilled />}
                                                    name="checkAgree"
                                                    color="error"
                                                    size="small"
                                                    checked={checkAgree}
                                                    onChange={(event) => setCheckAgree(event.target.checked)}
                                                />
                                            }
                                            label={<Typography variant="body2">개인정보 수집 및 이용(필수)</Typography>}
                                        />
                                        <Link variant="caption" color="text.primary" href="/terms2.html" target="_blank" underline="always">
                                            보기
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} mt={2}>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                            disableElevation
                                            loading={isSubmitting}
                                            loadingPosition="end"
                                            sx={{
                                                mt: { sm: 2, xs: 0 },
                                                bgcolor: '#000',
                                                '&:hover': {
                                                    backgroundColor: '#404040'
                                                },
                                                py: '12px',
                                                borderRadius: '30px'
                                            }}
                                        >
                                            <Typography variant="body1">가입완료</Typography>
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default AuthRegister;
