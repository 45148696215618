import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import {
    Typography,
    Container,
    Stack,
    Box,
    IconButton,
    Divider,
    List,
    ListItem,
    ListItemButton,
    Drawer,
    ListItemText
} from '@mui/material';

//asset
import { CloseOutlined, RightOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { FaAngleDown } from 'react-icons/fa';

import { logout, activeLogin } from 'store/reducers/auth';
import StickyHeader from './StickyHeader';

const menuItems = [
    { id: 'dashboard', title: 'Dashboard', url: '/writer/dashboard' },
    { id: 'order', title: '실무센터', url: '', category: true },
    { id: 'order_list', title: '작업 진행', url: '/writer/order-list' },
    { id: 'order_notice', title: '관리자 알림', url: '/writer/order-notice' },
    { id: 'tools', title: '도구', url: '', category: true },
    { id: 'tools_morpheme', title: '형태소 분석', url: '/writer/morpheme' },
    { id: 'tools_paragraphy', title: '자동 줄바꿈', url: '/writer/paragraphy' },
    { id: 'tools_algorithm', title: '알고리즘 분석', url: '/writer/algorithm' },
    { id: 'test', title: '채용센터', url: '', category: true },
    { id: 'test_schedule', title: '신입 테스트', url: '/writer/test-schedule' },
    { id: 'test_guide', title: '테스트 작성방법', url: '/writer/test-guide' },
    { id: 'service', title: '고객센터', url: '', category: true },
    { id: 'service_notice', title: '공지사항', url: '/writer/service-notice' },
    { id: 'service_faq', title: 'FAQ', url: '/writer/service-faq' },
    { id: 'service_question', title: '1:1문의', url: '/writer/service-question' }
];

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        position: 'fixed',
        width: '100%',
        top: 0,
        padding: '15px',
        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
        animation: 'headerSlideDown 0.8s ease forwards'
    }
});

const HeaderWriter = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sticky = StickyHeader(100);
    const classes = useStyles();
    const isLoggedIn = useSelector((state) => state.auth.writer.isLoggedIn);

    const [openMenu, setOpenMenu] = useState(false);

    const onCloseDrawer = () => {
        setOpenMenu(false);
    };

    const openLogin = () => {
        dispatch(activeLogin({ open: true }));
        onCloseDrawer();
    };

    const handleLogout = () => {
        dispatch(logout({ type: 'writer' }));
        onCloseDrawer();
        navigate('/writer/home');
    };

    const selectMenu = (tab) => {
        // setCurTab(tab.id);
        navigate(tab.url);
        setOpenMenu(false);
    };

    return (
        <>
            <header style={{ width: '100%', position: 'absolute', zIndex: 4, top: 0 }}>
                <Box
                    p={{ md: '20px 0', xs: '30px 10px 10px 20px' }}
                    sx={{ transition: 'all 0.3s ease-in-out' }}
                    className={sticky ? classes.root : ''}
                >
                    <Container>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h5" component={Link} to="/writer/home" color="black">
                                원고판다 채용
                            </Typography>
                            <Box pl={{ md: '140px' }} display={{ xs: 'none', md: 'block' }}>
                                <nav>
                                    <ul className="mainmenu">
                                        <li>
                                            <Link to="/writer/dashboard">Dashboard</Link>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link to="#">
                                                실무센터 <FaAngleDown />
                                            </Link>
                                            <ul className="axil-submenu">
                                                <li>
                                                    <Link to="/writer/order-list">작업 진행</Link>
                                                </li>
                                                <li>
                                                    <Link to="/writer/order-notice">관리자 알림</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link to="#">
                                                도구 <FaAngleDown />
                                            </Link>
                                            <ul className="axil-submenu">
                                                <li>
                                                    <Link to="/writer/morpheme">형태소 분석</Link>
                                                </li>
                                                <li>
                                                    <Link to="/writer/paragraphy">자동 줄바꿈</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link to="#">
                                                채용센터 <FaAngleDown />
                                            </Link>
                                            <ul className="axil-submenu">
                                                <li>
                                                    <Link to="/writer/test-schedule">신입 테스트</Link>
                                                </li>
                                                <li>
                                                    <Link to="/writer/test-guide">테스트 작성방법</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link to="#">
                                                고객센터 <FaAngleDown />
                                            </Link>
                                            <ul className="axil-submenu">
                                                <li>
                                                    <Link to="/writer/service-notice">공지사항</Link>
                                                </li>
                                                <li>
                                                    <Link to="/writer/service-faq">FAQ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/writer/service-question">1:1문의</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </Box>
                            <Stack mr="50px" flex={1} alignItems="flex-end" visibility={{ md: 'visible', xs: 'hidden' }}>
                                {!isLoggedIn ? (
                                    <Typography variant="h6" color="black" component={Link} to="#" onClick={openLogin}>
                                        들어가기
                                    </Typography>
                                ) : (
                                    <Stack direction="row" spacing={3}>
                                        <Typography variant="h6" component={Link} to="/writer/profile" color="black">
                                            마이페이지
                                        </Typography>
                                        <Typography variant="h6" component={Link} to="/writer/home" color="black" onClick={handleLogout}>
                                            나가기
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                            <Stack display={{ md: 'none', xs: 'block' }}>
                                <IconButton onClick={() => setOpenMenu(true)} sx={{ borderRadius: '50%', bgcolor: '#ecf2f6' }}>
                                    <UnorderedListOutlined style={{ color: 'black' }} />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Container>
                </Box>
            </header>

            <Drawer anchor="left" open={openMenu} onClose={onCloseDrawer} sx={{ display: { md: 'none', xs: 'block' } }}>
                <Box role="presentation" p="30px 16px" width="80vw">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" pl={2}>
                        <Typography variant="h6" component={Link} to="/writer/home" color="black" onClick={onCloseDrawer}>
                            원고판다 채용
                        </Typography>
                        <IconButton onClick={onCloseDrawer}>
                            <CloseOutlined />
                        </IconButton>
                    </Stack>
                    <Stack pt={1}>
                        <Divider sx={{ mt: 0.5, mb: 1 }} />
                        <List sx={{ pl: 2, pr: 2 }}>
                            {menuItems.map((item) => (
                                <ListItem key={item.id} disablePadding>
                                    {item.category ? (
                                        <ListItemText sx={{ py: '10px' }}>
                                            <Stack spacing={1} borderLeft="5px solid #ff4d4f60" borderBottom="1px solid #ff4d4f60">
                                                {/*<Box width="4px" height="16px" bgcolor="#ff4f4f" />*/}
                                                <Typography fontWeight="bold" ml="5px">
                                                    {item.title}{' '}
                                                </Typography>
                                            </Stack>
                                        </ListItemText>
                                    ) : (
                                        <ListItemButton sx={{ px: '15px', py: '8px' }} onClick={() => selectMenu(item)}>
                                            <Stack direction="row" justifyContent="space-between" flex={1} alignItems="center">
                                                <Typography variant="body1">{item.title} </Typography>
                                                <RightOutlined style={{ color: '#c0c0c0', fontSize: '11px' }} />
                                            </Stack>
                                        </ListItemButton>
                                    )}
                                </ListItem>
                            ))}
                            <Divider />
                            {!isLoggedIn ? (
                                <ListItem key="login" disablePadding>
                                    <ListItemButton sx={{ px: '15px', py: '15px' }} onClick={openLogin}>
                                        <Typography variant="body1">들어가기</Typography>
                                    </ListItemButton>
                                </ListItem>
                            ) : (
                                <>
                                    <ListItem key="login" disablePadding>
                                        <ListItemButton
                                            sx={{ px: '15px', py: '10px' }}
                                            onClick={() => {
                                                navigate('/writer/profile');
                                                setOpenMenu(false);
                                            }}
                                        >
                                            <Typography variant="body1">마이페이지</Typography>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem key="login" disablePadding>
                                        <ListItemButton sx={{ px: '15px', py: '10px' }} onClick={handleLogout}>
                                            <Typography variant="body1">나가기</Typography>
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </Stack>
                </Box>
            </Drawer>
        </>
    );
};

export default HeaderWriter;
