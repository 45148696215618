// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    padding: 16,
                    borderColor: theme.palette.divider
                },
                head: {
                    fontWeight: 400,
                    paddingTop: 20,
                    paddingBottom: 20,
                    color: '#282828'
                },
                sizeSmall: {
                    padding: '12px 10px'
                }
            }
        }
    };
}
