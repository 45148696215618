// ==============================|| THEME CONFIG  ||============================== //

const config = {
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr',
    itemNumOfPage: 10,

    BOARD_TYPE: {
        CLIENT_NOTICE: 1,
        CLIENT_FAQ: 2,
        CLIENT_QUESTION: 3,
        CLIENT_SAMPLE: 4,
        WRITER_NOTICE: 5,
        WRITER_FAQ: 6,
        WRITER_QUESTION: 7,
        WRITER_TEST_SCHEDULE: 8,
        WRITER_TEST_GUIDE: 9
    }
};

export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';
