import { Container, Stack, Typography } from '@mui/material';

const ErrorPage = () => {
    return (
        <Container maxWidth="sm">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ minHeight: '80vh' }}>
                <img src="/images/icon/404.png" alt="error" style={{ width: '80%', marginBottom: '3em' }} />
                <Typography variant="h2" gutterBottom="true">
                    Page Not Found
                </Typography>
                <Typography variant="body1" color="textSecondary" textAlign="center">
                    The page you are looking was moved, removed,
                    <br /> renamed, or might never exist!
                </Typography>
            </Stack>
        </Container>
    );
};

export default ErrorPage;
