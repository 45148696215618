// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    drawerOpen: false
};

// ==============================|| SLICE - MENU ||============================== //

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        }
    }
});

export default menuSlice.reducer;

export const { activeItem, openDrawer } = menuSlice.actions;
