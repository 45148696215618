import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Stack, Container, Link, Toolbar, Typography, useMediaQuery } from '@mui/material';

import { logout } from 'store/reducers/auth';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const token = useSelector((state) => state.auth.manager.token);

    const handleLogout = () => {
        dispatch(logout({ type: 'manager' }));
        navigate('/manager/login');
        return;
    };

    useEffect(() => {
        if (token) {
            const decodeJwt = jwt_decode(token);
            if (decodeJwt.exp * 1000 > Date.now()) return;
            dispatch(logout({ type: 'manager' }));
            navigate('/manager/login');
            console.log('manager token expire');
        }
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <AppBar sx={{ p: 1, bgcolor: 'white' }}>
                <Container maxWidth="xl">
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h5" color="black">
                            원고판다 1.0.4
                        </Typography>
                        <Stack direction="row" flex={1} spacing={2} ml="120px" display={{ xs: 'none', sm: 'flex' }}>
                            <Link href="/manager/order-manage" variant="h6" underline="none" color="black">
                                Sample
                            </Link>
                            <Link href="/manager/test" variant="h6" underline="none" color="black">
                                Test
                            </Link>
                        </Stack>
                        <Link color="black" onClick={() => handleLogout()} href="#">
                            <Typography variant="h6">로그아웃</Typography>
                        </Link>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box height="140px" />
            <Outlet />
        </Box>
    );
};

export default MainLayout;
