// assets
import { AuditOutlined, NotificationOutlined, QuestionCircleOutlined, FileSearchOutlined } from '@ant-design/icons';

// icons
const icons = {
    AuditOutlined,
    NotificationOutlined,
    QuestionCircleOutlined,
    FileSearchOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const client = {
    id: 'client_panel',
    title: 'CLIENT MANAGER',
    type: 'group',
    children: [
        {
            id: 'wongo_sample',
            title: '원고샘플',
            type: 'item',
            url: '/admin/wongo-sample',
            icon: icons.AuditOutlined
        },
        {
            id: 'client_notice',
            title: '공지사항',
            type: 'item',
            url: '/admin/client-notice',
            icon: icons.NotificationOutlined
        },
        {
            id: 'client_faq',
            title: 'FAQ',
            type: 'item',
            url: '/admin/client-faq',
            icon: icons.FileSearchOutlined
        },
        {
            id: 'client_question',
            title: '문의게시판',
            type: 'item',
            url: '/admin/client-question',
            icon: icons.QuestionCircleOutlined
        }
    ]
};

export default client;
