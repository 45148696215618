import PropTypes from 'prop-types';
import { InputLabel, Stack, TextField, Typography } from '@mui/material';

const TextBox = ({ name, title, value, placeholder, helperText, readonly, required, sx, ...others }) => {
    return (
        <Stack spacing={0.5} direction="column" sx={{ ...sx }}>
            <InputLabel htmlFor={name} sx={{ color: 'secondary.main' }}>
                {title}{' '}
                {required && (
                    <Typography component="span" color="error">
                        *
                    </Typography>
                )}
            </InputLabel>
            <TextField
                id={name}
                name={name}
                value={value}
                placeholder={placeholder}
                helperText={helperText}
                fullWidth
                InputProps={{ readOnly: readonly }}
                {...others}
            />
        </Stack>
    );
};

TextBox.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    sx: PropTypes.object,
    others: PropTypes.object
};

export default TextBox;
