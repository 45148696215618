// assets
import { TeamOutlined, SketchOutlined, DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
    TeamOutlined,
    SketchOutlined,
    DashboardOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const admin = {
    id: 'admin_panel',
    title: 'ADMIN MANAGER',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/admin/dashboard',
            icon: icons.DashboardOutlined
        },
        {
            id: 'client_manage',
            title: '클라이언트 관리',
            type: 'item',
            url: '/admin/client-manage',
            icon: icons.TeamOutlined
        },
        {
            id: 'writer_manage',
            title: '작가 관리',
            type: 'item',
            url: '/admin/writer-manage',
            icon: icons.TeamOutlined
        },
        {
            id: 'point_manage',
            title: '포인트충전 관리',
            type: 'item',
            url: '/admin/point-manage',
            icon: icons.SketchOutlined
        }
    ]
};

export default admin;
