import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/manager';

// render - authentification
const AuthLogin = Loadable(lazy(() => import('pages/manager/AuthLogin')));
const OrderManage = Loadable(lazy(() => import('pages/manager/OrderManage')));
const TestManage = Loadable(lazy(() => import('pages/manager/Test')));

const ManagerRoutes = {
    path: 'manager',
    element: <MinimalLayout />,
    children: [
        {
            index: true,
            element: <AuthLogin />
        },
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: '',
            element: <MainLayout />,
            children: [
                {
                    path: 'order-manage',
                    element: <OrderManage />
                },
                {
                    path: 'test',
                    element: <TestManage />
                }
            ]
        }
    ]
};
export default ManagerRoutes;
