import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined, ExportOutlined } from '@ant-design/icons';

// project import
import { logout } from 'store/reducers/auth';

import AppBarStyled from './AppBarStyled';
import Notification from './Notification';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    const handleLogout = () => {
        // logout
        dispatch(logout({ type: 'admin' }));
        navigate('/admin/login');
    };

    // common header
    const mainHeader = (
        <Toolbar>
            <IconButton
                disableRipple
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
            >
                {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            <Box sx={{ width: '100%', ml: 1 }} />
            <Notification />
            <IconButton onClick={handleLogout} sx={{ mx: 1 }}>
                <ExportOutlined style={{ fontSize: '1.2rem' }} />
            </IconButton>
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
