import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import WriterLayout from 'layout/user/WriterLayout';

const WriterHome = Loadable(lazy(() => import('pages/writer/Home')));
const WriterNotice = Loadable(lazy(() => import('pages/writer/Notice')));
const WriterFAQ = Loadable(lazy(() => import('pages/writer/FAQ')));
const WriterQuestion = Loadable(lazy(() => import('pages/writer/Question')));
const Profile = Loadable(lazy(() => import('pages/writer/Profile')));
const TestGuide = Loadable(lazy(() => import('pages/writer/TestGuide')));
const TestSchedule = Loadable(lazy(() => import('pages/writer/TestSchedule')));
const Dashboard = Loadable(lazy(() => import('pages/writer/Dashboard')));
const OrderList = Loadable(lazy(() => import('pages/writer/OrderList')));
const OrderNotice = Loadable(lazy(() => import('pages/writer/OrderNotice')));
const Morpheme = Loadable(lazy(() => import('pages/writer/Morpheme')));
const Paragraphy = Loadable(lazy(() => import('pages/writer/Paragraphy')));
const Algorithm = Loadable(lazy(() => import('pages/writer/Algorithm')));

// ==============================|| MAIN ROUTING ||============================== //

const WriterRoutes = {
    path: 'writer',
    element: <WriterLayout />,
    children: [
        {
            index: true,
            element: <WriterHome />
        },
        {
            path: 'home',
            element: <WriterHome />
        },
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'order-list',
            element: <OrderList />
        },
        {
            path: 'order-notice',
            element: <OrderNotice />
        },
        {
            path: 'service-notice',
            element: <WriterNotice />
        },
        {
            path: 'service-faq',
            element: <WriterFAQ />
        },
        {
            path: 'service-question',
            element: <WriterQuestion />
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'test-schedule',
            element: <TestSchedule />
        },
        {
            path: 'test-guide',
            element: <TestGuide />
        },
        {
            path: 'morpheme',
            element: <Morpheme />
        },
        {
            path: 'paragraphy',
            element: <Paragraphy />
        },
        {
            path: 'algorithm',
            element: <Algorithm />
        }
    ]
};

export default WriterRoutes;
