import PropTypes from 'prop-types';

// material-ui
import { Card, Divider, Typography } from '@mui/material';

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = ({ title, children }) => {
    return (
        <Card elevation={0} sx={{ border: '1px solid #ccd0d4', borderRadius: '3px', mb: 4, p: { md: '50px 40px', xs: '40px 20px' } }}>
            <Typography sx={{ px: 1 }} variant="h5" fontSize={{ md: '20px', xs: '16px' }}>
                {title}
            </Typography>
            {title && <Divider sx={{ borderColor: '#808080', borderWidth: '1px' }} />}
            {children}
        </Card>
    );
};

MainCard.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node
};

export default MainCard;
