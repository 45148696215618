// third-party
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

// import reducers from './reducers';
import menu from './reducers/menu';
import auth from './reducers/auth';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
    key: 'root',
    storage
    // whitelist: ['auth']
};

const rootReducer = combineReducers({ menu, auth });
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer
});

export const persistor = persistStore(store);
