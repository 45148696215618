import PropTypes from 'prop-types';

// material-ui
import { Stack } from '@mui/material';

const MyStack = ({ sx = {}, children, ...others }) => {
    return (
        <Stack
            direction={{ sm: 'row', xs: 'column' }}
            alignItems="center"
            spacing={{ sm: 2, xs: 1 }}
            {...others}
            sx={{
                ...sx
            }}
        >
            {children}
        </Stack>
    );
};

MyStack.propTypes = {
    sx: PropTypes.object,
    children: PropTypes.node,
    others: PropTypes.object
};

export default MyStack;
