// project import
import admin from './admin';
import order from './order';
import client from './client';
import writer from './writer';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [admin, order, client, writer]
};

export default menuItems;
