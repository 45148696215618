// project import
import MinimalLayout from 'layout/MinimalLayout';
import UserEntry from 'pages/UserEntry';
import ResetPassword from 'pages/ResetPassword';
import FindID from 'pages/FindID';
import ConfirmEmail from '../pages/ConfirmEmail';

// ==============================|| MAIN ROUTING ||============================== //

const UserRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            index: true,
            element: <UserEntry />
        },
        {
            path: 'reset-pwd',
            element: <ResetPassword />
        },
        {
            path: 'find-id',
            element: <FindID />
        }
    ]
};

export default UserRoutes;
