// assets
import {
    InboxOutlined,
    EditOutlined,
    NotificationOutlined,
    QuestionCircleOutlined,
    FileSearchOutlined,
    ProfileOutlined,
    BulbOutlined
} from '@ant-design/icons';

// icons
const icons = {
    InboxOutlined,
    EditOutlined,
    NotificationOutlined,
    QuestionCircleOutlined,
    FileSearchOutlined,
    ProfileOutlined,
    BulbOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const writer = {
    id: 'writer_panel',
    title: 'WRITER MANAGER',
    type: 'group',
    children: [
        {
            id: 'writer_category',
            title: '카테고리 관리',
            type: 'item',
            url: '/admin/writer-category',
            icon: icons.InboxOutlined
        },
        {
            id: 'writer_guide',
            title: '글쓰기 관리',
            type: 'item',
            url: '/admin/writer-guide',
            icon: icons.EditOutlined
        },
        {
            id: 'writer_notice',
            title: '공지사항',
            type: 'item',
            url: '/admin/writer-notice',
            icon: icons.NotificationOutlined
        },
        {
            id: 'writer_faq',
            title: 'FAQ',
            type: 'item',
            url: '/admin/writer-faq',
            icon: icons.FileSearchOutlined
        },
        {
            id: 'writer_question',
            title: '문의게시판',
            type: 'item',
            url: '/admin/writer-question',
            icon: icons.QuestionCircleOutlined
        },
        {
            id: 'test_schedule',
            title: '테스트일정',
            type: 'item',
            url: '/admin/test-schedule',
            icon: icons.ProfileOutlined
        },
        {
            id: 'test_guide',
            title: '테스트작성방법',
            type: 'item',
            url: '/admin/test-guide',
            icon: icons.BulbOutlined
        }
    ]
};

export default writer;
