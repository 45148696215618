import { useRoutes } from 'react-router-dom';

// project import
import AdminRoutes from './AdminRoutes';
import UserRoutes from './UserRoutes';
import ErrorRoutes from './ErrorRoutes';
import ClientRoutes from './ClientRoutes';
import WriterRoutes from './WriterRoutes';
import ManagerRoutes from './ManagerRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([ErrorRoutes, UserRoutes, AdminRoutes, ClientRoutes, WriterRoutes, ManagerRoutes]);
}
