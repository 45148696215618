import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../store/reducers/auth';
import { useNavigate } from 'react-router-dom';

const useAxios = (user) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth[user].token);

    const api = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL + '/api',
        timeout: 100000,
        headers: { token: token || '' }
    });

    // api.interceptors.request.use(
    //     function (config) {
    //         // Do something before request is sent
    //         return config;
    //     },
    //     function (error) {
    //         // Do something with request error
    //         return Promise.reject(error);
    //     }
    // );
    //
    api.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // console.log(response);
            return response;
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status === 401) {
                console.log('useAxios: token expired');
                console.log('user:', user);
                dispatch(logout({ type: user }));
                if (user === 'admin' || user === 'manager') {
                    navigate(`/${user}/login`);
                }
            }
            console.log(error);
            return Promise.reject(error);
        }
    );

    return api;
};

export default useAxios;
