import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SwipeableViews from 'react-swipeable-views';

//material-ui
import { Box, Divider, Stack, Typography, Container, Paper } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

// project import
import useAxios from 'utils/useAxios';
import TextBox from 'components/TextBox';

const FindID = () => {
    const api = useAxios('client');

    const [suceess, setSuccess] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const resetPwd = async (email) => {
        const response = await api.post('/login/find-ip', { email: email });
        if (response.data.status === 'success') {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
        setTabIndex(1);
    };

    return (
        <Container maxWidth="sm" sx={{ mt: '120px' }}>
            <Paper elevation={0} sx={{ border: '1px solid #ccd0d4', borderRadius: '3px', mb: 4, p: { md: '60px 40px', xs: '40px 20px' } }}>
                <Typography sx={{ px: 1 }} variant="h5" fontSize={{ md: '20px', xs: '16px' }}>
                    아이디 찾기
                </Typography>
                <Divider sx={{ borderColor: '#808080', borderWidth: '1px' }}></Divider>

                <SwipeableViews index={tabIndex}>
                    <Stack mt="40px">
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required('이메일을 입력해주세요').email('이메일형식이 틀립니다.')
                            })}
                            onSubmit={async (values, { setErrors, setSubmitting }) => {
                                try {
                                    setSubmitting(true);

                                    await resetPwd(values.email);
                                } catch (err) {
                                    setErrors({ submit: err.message });
                                }
                                setSubmitting(false);
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={3} p={2}>
                                        <TextBox
                                            name="email"
                                            title="이메일"
                                            type="email"
                                            placeholder="이메일을 입력해주세요"
                                            helperText="가입시 이메일과 같아야 합니다"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.email && errors.email)}
                                            sx={{ pb: 3 }}
                                        />

                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            disableElevation
                                            loading={isSubmitting}
                                            loadingPosition="end"
                                            sx={{
                                                bgcolor: '#000',
                                                '&:hover': {
                                                    backgroundColor: '#404040'
                                                }
                                            }}
                                        >
                                            <span>확인</span>
                                        </LoadingButton>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Stack>
                    <Stack p={2} mt="20px">
                        {suceess ? (
                            <Typography sx={{ fontStyle: 'italic' }} textAlign="justify">
                                회원님의 이메일로 아이디가 전송되었습니다.
                                <br /> 만일 메일이 도착하지 않았으면 스펨함을 확인해 보세요.
                            </Typography>
                        ) : (
                            <Typography sx={{ fontStyle: 'italic' }}>이메일정보가 정확치 않습니다.</Typography>
                        )}
                    </Stack>
                </SwipeableViews>
            </Paper>
        </Container>
    );
};

export default FindID;
