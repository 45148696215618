// project import
import ErrorPage from '../pages/NotFound';

// ==============================|| MAIN ROUTING ||============================== //

const ErrorRoutes = {
    path: '*',
    element: <ErrorPage />
};

export default ErrorRoutes;
