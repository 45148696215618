import { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import Header from './header/HeaderClient';
import LoginDialog from 'pages/client/LoginDialog';
import RegisterDialog from 'pages/client/RegisterDialog';
import AlertResult from 'components/AlertResult';

import { logout } from 'store/reducers/auth';
import { activeLogin, activeRegister } from 'store/reducers/auth';

import '@toast-ui/editor/dist/toastui-editor.css';

import { Fab } from '@mui/material';

const ClientLayout = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.client.token);
    const openLoginDlg = useSelector((state) => state.auth.openLogin);
    const openRegisterDlg = useSelector((state) => state.auth.openRegister);
    const navigate = useNavigate();

    const [openSnack, setOpenSnack] = useState(false);
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState('success');

    useEffect(() => {
        onCloseLoginDlg();
        onCloseRegisterDlg();
        if (token) {
            const decodeJwt = jwt_decode(token);
            if (decodeJwt.exp * 1000 < Date.now()) {
                dispatch(logout({ type: 'client' }));
                console.log('clientlayout: token expired');
            }
        }
    }, []);

    const onShowAlert = (msg, serverity) => {
        setMsg(msg);
        setSeverity(serverity);
        setOpenSnack(true);
    };

    const closeSnack = () => {
        setOpenSnack(false);
    };

    const onCloseLoginDlg = () => {
        dispatch(activeLogin({ open: false }));
    };

    const onCloseRegisterDlg = () => {
        dispatch(activeRegister({ open: false }));
    };

    return (
        <main className="main-wrapper">
            <Header />
            <Outlet />
            {/*<Fab*/}
            {/*    size="large"*/}
            {/*    disableRipple*/}
            {/*    sx={{ position: 'fixed', right: { md: '48px', xs: '20px' }, bottom: { md: '120px', xs: '90px' } }}*/}
            {/*    onClick={() => navigate('/client/morpheme')}*/}
            {/*>*/}
            {/*    <img src="/images/icon/morpheme.png" alt="morpheme" width={64} height={64} />*/}
            {/*</Fab>*/}
            <Fab
                size="large"
                disableRipple
                sx={{ position: 'fixed', right: { md: '48px', xs: '20px' }, bottom: { md: '48px', xs: '20px' } }}
                onClick={() => window.open('http://pf.kakao.com/_VxfHYT/chat')}
            >
                <img src="/images/icon/talk.png" alt="katalk" width={64} height={64} />
            </Fab>
            {openLoginDlg && <LoginDialog openDlg={openLoginDlg} onCloseDlg={onCloseLoginDlg} onShowAlert={onShowAlert} />}
            {openRegisterDlg && <RegisterDialog openDlg={openRegisterDlg} onCloseDlg={onCloseRegisterDlg} onShowAlert={onShowAlert} />}
            <AlertResult openSnack={openSnack} closeSnack={closeSnack} msg={msg} severity={severity} ms={2000} />
        </main>
    );
};

export default ClientLayout;
